'use strict';

(function($, undefined)
{
  var openedID = null;
  var $openedBlock = null;
  var $bioContent = $('#bio-content');
  var $bioAside = $('#bio-aside');

  var openBio = function(id, div)
  {
    openedID = id;
    $openedBlock = div;

    var $btnOpen = $openedBlock.find('.is-btn-open');
    var $btnClose = $openedBlock.find('.is-btn-close');
    var $bioLong = $bioContent.find('.bio-long[data-bio="' + openedID + '"]');

    $bioLong.addClass('focused');

    $openedBlock.addClass('focused');
    $btnOpen.addClass('hidden');
    $btnClose.removeClass('hidden');

    $bioContent.addClass('opened');
    $bioAside.addClass('hidden');
  };

  var closeBio = function(nextOpen)
  {
    var $btnOpen = $openedBlock.find('.is-btn-open');
    var $btnClose = $openedBlock.find('.is-btn-close');

    $openedBlock.removeClass('focused');
    $btnOpen.removeClass('hidden');
    $btnClose.addClass('hidden');

    $('.bio-long').removeClass('focused');

    if (typeof nextOpen === 'object' && nextOpen !== null)
    {
      setTimeout(function()
      {
        openBio(nextOpen.id, nextOpen.div);
      }, 50);
    } else
    {
      $bioContent.removeClass('opened');
      $bioAside.removeClass('hidden');
    }

    openedID = null;
    $openedBlock = null;
  };

  $('.btn-read-more').on('click', function(event)
  {
    var $self = $(this);
    var bioID = $self.data('bio-id');
    var $parent = $self.parents('.bio-media');

    if (openedID === bioID)
    {
      closeBio();

    } else if (typeof openedID === 'string')
    {
      closeBio({id: bioID, div: $parent});

    } else
    {
      openBio(bioID, $parent);
    }
  });

})(window.jQuery);
