'use strict';

(function($, undefined)
{
  /* jshint devel:true */
  $('.details__opener').on('touchstart click', function(event)
  {
    var $self = $(this);
    var id = $self.data('rel');
    $('#' + id).show();
    $self.hide();
    $('.details__closer[data-rel="' + id + '"]').show();
    event.preventDefault();

  });

  $('.details__closer').on('touchstart click', function(event)
  {
    var $self = $(this);
    var id = $self.data('rel');
    $('#' + id).hide();
    $self.hide();
    $('.details__opener[data-rel="' + id + '"]').show();
    event.preventDefault();

  });

})(window.jQuery);
